import React from 'react'

const SvgLargeFileApp3 = props => (
  <svg id='largeFile_app3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>{'.largeFile_app3_svg__st4{fill:#fd5c1f}'}</style>
    <linearGradient
      id='largeFile_app3_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={16.55}
      y1={114.45}
      x2={143.45}
      y2={-12.45}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M153 101H7c-3.31 0-6-2.69-6-6V7c0-3.31 2.69-6 6-6h146c3.31 0 6 2.69 6 6v88c0 3.31-2.69 6-6 6z'
      fill='none'
      stroke='url(#largeFile_app3_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='largeFile_app3_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={54.465}
      y1={113}
      x2={104.465}
      y2={113}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#largeFile_app3_svg__SVGID_2_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M54.47 113h50'
    />
    <linearGradient
      id='largeFile_app3_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={72.65}
      y1={115.143}
      x2={86.281}
      y2={101.512}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#252a3a' stopOpacity={0.8} />
    </linearGradient>
    <path fill='url(#largeFile_app3_svg__SVGID_3_)' d='M89.42 112H69.51l2.65-10h14.61z' />
    <path
      d='M58.74 48.71c-5.52 0-10.02-4.04-10.02-9.02 0-4.92 4.49-8.93 10.02-8.93.56 0 1.11.03 1.67.11.73-3.53 3.87-6.38 7.87-7.11C68.76 17.77 74.42 13 81.22 13c7.06 0 12.81 5.05 12.96 11.32 2.73 1.2 4.93 3.28 6.15 5.8 4.13 1.39 6.06 4.88 6.06 9.88 0 4.56-4.05 8.63-9.59 8.63-6.77 0-13.08.02-19.34.04-6.16.02-12.28.04-18.72.04z'
      fill='none'
      stroke='#fd5c1f'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      className='largeFile_app3_svg__st4'
      d='M134.73 66h-26.75c-.89 0-1.58-.73-1.58-1.67v-6.66c0-.89.74-1.67 1.58-1.67h26.75c.91 0 1.67.77 1.67 1.67v6.66c0 .95-.72 1.67-1.67 1.67zm-26.33-2h26v-6h-26v6z'
    />
    <path
      className='largeFile_app3_svg__st4'
      d='M115.4 62h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM122.4 62h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM129.4 62h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM92.73 78H65.98c-.89 0-1.58-.73-1.58-1.67v-6.66c0-.89.74-1.67 1.58-1.67h26.75c.91 0 1.67.77 1.67 1.67v6.66c0 .95-.72 1.67-1.67 1.67zM66.4 76h26v-6h-26v6z'
    />
    <path
      className='largeFile_app3_svg__st4'
      d='M73.4 74h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM80.4 74h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM87.4 74h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM92.73 90H65.98c-.89 0-1.58-.73-1.58-1.67v-6.66c0-.89.74-1.67 1.58-1.67h26.75c.91 0 1.67.77 1.67 1.67v6.66c0 .95-.72 1.67-1.67 1.67zM66.4 88h26v-6h-26v6z'
    />
    <path
      className='largeFile_app3_svg__st4'
      d='M73.4 86h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM80.4 86h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1zM87.4 86h-3c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1z'
    />
    <linearGradient
      id='largeFile_app3_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={97.603}
      y1={51.793}
      x2={87.689}
      y2={61.707}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M105.4 60h-16V44c0-.55-.45-1-1-1s-1 .45-1 1v17c0 .55.45 1 1 1h17c.55 0 1-.45 1-1s-.45-1-1-1z'
      fill='url(#largeFile_app3_svg__SVGID_4_)'
    />
    <g>
      <path
        className='largeFile_app3_svg__st4'
        d='M22.4 64.33v-6.66c0-.91.77-1.67 1.67-1.67h26.75c.84 0 1.58.78 1.58 1.67v6.66c0 .94-.69 1.67-1.58 1.67H24.07c-.96 0-1.67-.72-1.67-1.67zm28-6.33h-26v6h26v-6z'
      />
      <path
        className='largeFile_app3_svg__st4'
        d='M43.4 62h3c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1s.44 1 1 1zM36.4 62h3c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1s.44 1 1 1zM29.4 62h3c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1s.44 1 1 1z'
      />
    </g>
    <linearGradient
      id='largeFile_app3_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={61.189}
      y1={51.793}
      x2={71.103}
      y2={61.707}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M53.4 60h16V44c0-.55.45-1 1-1s1 .45 1 1v17c0 .55-.45 1-1 1h-17c-.55 0-1-.45-1-1s.44-1 1-1z'
      fill='url(#largeFile_app3_svg__SVGID_5_)'
    />
    <linearGradient
      id='largeFile_app3_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={79.396}
      y1={43}
      x2={79.396}
      y2={67}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M79.4 67c-.55 0-1-.45-1-1V44c0-.55.45-1 1-1s1 .45 1 1v22c0 .55-.45 1-1 1z'
      fill='url(#largeFile_app3_svg__SVGID_6_)'
    />
  </svg>
)

export default SvgLargeFileApp3
