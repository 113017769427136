import React from 'react'

const SvgLargeFileApp1 = props => (
  <svg id='largeFile_app1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>{'.largeFile_app1_svg__st10{fill:#fd5c1f}'}</style>
    <path
      d='M123.93 83.63H91.06c-11.37 0-21.55-9.27-22.02-20.63-.47-11.19 7.5-20.64 18.3-22.13 3.3-8.35 11.13-13.7 20.18-13.7 9.04 0 16.88 5.35 20.18 13.71 6.01.85 11.37 4.28 14.8 9.51 3.55 5.4 4.45 12.07 2.48 18.29-2.79 8.8-11.45 14.95-21.05 14.95z'
      fill='none'
      stroke='#fd5c1f'
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={39.19}
      y1={31.445}
      x2={68.811}
      y2={1.824}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M81.5 18.13h-55c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h55c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_1_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={32.94}
      y1={39.195}
      x2={50.061}
      y2={22.074}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M56.5 32.13h-30c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h30c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_2_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={30.19}
      y1={50.445}
      x2={41.811}
      y2={38.824}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M45.5 46.13h-19c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h19c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_3_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={15.19}
      y1={18.445}
      x2={18.811}
      y2={14.824}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M18.5 18.13h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_4_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={15.19}
      y1={32.445}
      x2={18.811}
      y2={28.824}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M18.5 32.13h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_5_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={15.19}
      y1={46.445}
      x2={18.811}
      y2={42.824}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M18.5 46.13h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z'
      fill='url(#largeFile_app1_svg__SVGID_6_)'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={16.551}
      y1={114.584}
      x2={143.45}
      y2={-12.315}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M153 101.13H7c-3.31 0-6-2.69-6-6v-88c0-3.31 2.69-6 6-6h146c3.31 0 6 2.69 6 6v88c0 3.32-2.69 6-6 6z'
      fill='none'
      stroke='url(#largeFile_app1_svg__SVGID_7_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={55.001}
      y1={113.134}
      x2={105.001}
      y2={113.134}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#largeFile_app1_svg__SVGID_8_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M55 113.13h50'
    />
    <linearGradient
      id='largeFile_app1_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={72.788}
      y1={115.278}
      x2={86.42}
      y2={101.647}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#252a3a' stopOpacity={0.8} />
    </linearGradient>
    <path fill='url(#largeFile_app1_svg__SVGID_9_)' d='M89.56 112.13H69.65l2.65-10h14.61z' />
    <path
      className='largeFile_app1_svg__st10'
      d='M102.86 73.38c.83 0 1.5-.67 1.5-1.5v-23c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v23c0 .83.68 1.5 1.5 1.5z'
    />
    <path
      className='largeFile_app1_svg__st10'
      d='M103.93 47.82a1.49 1.49 0 00-2.12 0l-6.36 6.36a1.49 1.49 0 000 2.12c.59.59 1.54.59 2.12 0l6.36-6.36c.58-.58.58-1.53 0-2.12z'
    />
    <g>
      <path
        className='largeFile_app1_svg__st10'
        d='M111.5 47.38c-.83 0-1.5.67-1.5 1.5v23c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-23c0-.82-.67-1.5-1.5-1.5z'
      />
      <path
        className='largeFile_app1_svg__st10'
        d='M110.44 72.95c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12a1.49 1.49 0 00-2.12 0l-6.36 6.36a1.49 1.49 0 000 2.12z'
      />
    </g>
  </svg>
)

export default SvgLargeFileApp1
