import React from 'react'

const SvgLargeFileFeature1 = props => (
  <svg id='largeFile_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.largeFile_feature1_svg__st0{fill:#2b4b67}.largeFile_feature1_svg__st1{fill:#fd5c1f}'}</style>
    <g id='largeFile_feature1_svg__Combined-Shape_24_'>
      <path
        id='largeFile_feature1_svg__Shape_12_'
        className='largeFile_feature1_svg__st0'
        d='M47 19.81V6H3v38h21.91c.9.9 1.92 1.41 3.04 2H1V4h48v16.15c-.76-.09-1.51-.2-2-.34z'
      />
    </g>
    <path
      id='largeFile_feature1_svg__Shape_11_'
      className='largeFile_feature1_svg__st1'
      d='M37.21 19.16l-.71-.4-.71.4A27.46 27.46 0 0126 22.48v11.26c.03 2.59 1.4 4.93 3.55 6.05l6.95 3.78 6.95-3.78c2.14-1.12 3.52-3.47 3.55-6.05V22.48c-3.42-.47-6.73-1.6-9.79-3.32zm.01 14.31l-1.45 1.58-1.45-1.58-2.91-3.16 1.45-1.58 2.9 3.16 4.78-5.2L42 28.27l-4.78 5.2z'
    />
    <path
      id='largeFile_feature1_svg__Shape_10_'
      d='M36.64 43.43l-6.89-3.61c-2.28-1.17-3.73-3.63-3.75-6.33V22.52l.88-.12a24.38 24.38 0 008.53-2.86l1.23-.61.99.63c2.65 1.47 5.51 2.43 8.47 2.84l.9.12v10.97c-.02 2.7-1.48 5.16-3.75 6.33l-6.61 3.61z'
      fill='none'
      stroke='#2b4b67'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <g id='largeFile_feature1_svg__Rectangle_7_' transform='translate(0 8)'>
      <path id='largeFile_feature1_svg__Rectangle-path_25_' className='largeFile_feature1_svg__st0' d='M1 4h48v2H1z' />
    </g>
    <g id='largeFile_feature1_svg__Rectangle-2_4_' transform='translate(38 4)'>
      <path id='largeFile_feature1_svg__Rectangle-path_24_' className='largeFile_feature1_svg__st0' d='M0 4h2v2H0z' />
    </g>
    <g id='largeFile_feature1_svg__Rectangle-2-Copy-2_4_' transform='translate(34 4)'>
      <path id='largeFile_feature1_svg__Rectangle-path_23_' className='largeFile_feature1_svg__st0' d='M0 4h2v2H0z' />
    </g>
    <g id='largeFile_feature1_svg__Rectangle-4_2_' transform='translate(7 17)'>
      <path id='largeFile_feature1_svg__Rectangle-path_14_' className='largeFile_feature1_svg__st1' d='M1 5h10v2H1z' />
    </g>
    <g id='largeFile_feature1_svg__Rectangle-4-Copy_2_' transform='translate(7 23)'>
      <path id='largeFile_feature1_svg__Rectangle-path_6_' className='largeFile_feature1_svg__st1' d='M1 5h10v2H1z' />
    </g>
    <g id='largeFile_feature1_svg__Rectangle-4-Copy-2_2_' transform='translate(7 29)'>
      <path id='largeFile_feature1_svg__Rectangle-path_5_' className='largeFile_feature1_svg__st1' d='M1 5h10v2H1z' />
    </g>
  </svg>
)

export default SvgLargeFileFeature1
