import React from 'react'

const SvgLargeFileFeature2 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path d='M2 6v38h46V13H32.58l-6.83-7H2z' fill='none' stroke='#334966' strokeWidth={2} />
    <path fill='#fd5c1f' d='M3 37h44v6H3z' />
    <path d='M32.73 26L29 22.5l1.6-1.5 6.4 6-6.4 6-1.6-1.5 3.73-3.5H13v-2h19.73z' fill='#334966' />
  </svg>
)

export default SvgLargeFileFeature2
