import React from 'react'

const SvgLargeFileApp4 = props => (
  <svg x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>
      {
        '.largeFile_app4_svg__st0{fill:#fd5c1f}.largeFile_app4_svg__st3{fill:none;stroke:#fd5c1f;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10}'
      }
    </style>
    <path
      className='largeFile_app4_svg__st0'
      d='M47.2 62.3c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9zm0 4.5c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zM60.5 56.2c-.9-.5-1.9-.3-2.6.3l-7.2-4.2c.1-.4.2-.8.2-1.2 0-.4-.1-.8-.2-1.2l6.5-3.8-.6-1.1-6.5 3.8c-.5-.6-1.3-1.1-2.2-1.2v-8.3c.9-.3 1.6-1.1 1.6-2.1 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 1 .7 1.8 1.6 2.1v8.3c-.9.2-1.6.6-2.2 1.2L37.9 45l-.7 1.1 6.5 3.8c-.1.4-.2.8-.2 1.2 0 .4.1.8.2 1.2l-7.2 4.2c-.7-.6-1.7-.8-2.6-.3-1.1.6-1.4 2-.8 3s2 1.4 3 .8c.9-.5 1.2-1.5 1-2.4l7.2-4.2c.5.6 1.3 1.1 2.2 1.2v7.5h1.3v-7.5c.9-.2 1.6-.6 2.2-1.2l7.2 4.2c-.2.9.2 1.9 1 2.4 1.1.6 2.4.2 3-.8s.4-2.3-.7-3z'
    />
    <path
      className='largeFile_app4_svg__st0'
      d='M33.6 46.6c.4.2.9.4 1.4.4 1 0 2-.5 2.5-1.4.8-1.4.3-3.1-1-3.9-1.3-.8-3.2-.3-3.9 1-.8 1.4-.3 3.1 1 3.9zm.1-3.3c.3-.5.8-.8 1.4-.8.3 0 .5.1.8.2.8.4 1 1.4.6 2.1-.4.7-1.4 1-2.1.6-.9-.4-1.1-1.3-.7-2.1zM58 41.6c-.7.4-1.1 1-1.3 1.7s-.1 1.5.3 2.2c.5.9 1.5 1.4 2.5 1.4.5 0 1-.1 1.4-.4.7-.4 1.1-1 1.3-1.7s.1-1.5-.3-2.2c-.8-1.2-2.6-1.7-3.9-1zm2.9 2.9c-.1.4-.4.7-.7 1-.7.4-1.7.2-2.1-.6-.2-.4-.3-.8-.2-1.2.1-.4.4-.7.7-1 .2-.1.5-.2.8-.2.6 0 1.1.3 1.4.8.2.4.2.8.1 1.2z'
    />
    <path
      className='largeFile_app4_svg__st0'
      d='M72.3 39.6c0-1.9-1.3-4.2-2.9-5.1L50.2 23.6c-1.6-.9-4.3-.9-5.9 0L25.2 34.5c-1.6.9-3 3.2-3 5.1v23c0 1.9 1.3 4.2 3 5.1l19.1 10.9c.8.5 1.8.7 2.9.7s2.1-.2 2.9-.7l19.1-10.9c1.6-.9 2.9-3.2 2.9-5.1.2 0 .2-23 .2-23zm-3.7 26.2L48.9 77c-.9.5-2.4.5-3.3 0L25.9 65.8c-.9-.5-1.6-1.8-1.6-2.8V39.2c0-1 .7-2.3 1.6-2.8l19.7-11.2c.4-.2 1-.4 1.7-.4s1.3.1 1.7.4l19.7 11.2c.9.5 1.6 1.8 1.6 2.8V63c0 1-.8 2.3-1.7 2.8z'
    />
    <linearGradient
      id='largeFile_app4_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={16.552}
      y1={1.552}
      x2={143.448}
      y2={128.448}
      gradientTransform='matrix(1 0 0 -1 0 116)'
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M153 101H7c-3.3 0-6-2.7-6-6V7c0-3.3 2.7-6 6-6h146c3.3 0 6 2.7 6 6v88c0 3.3-2.7 6-6 6z'
      fill='none'
      stroke='url(#largeFile_app4_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='largeFile_app4_svg__SVGID_00000173125541893308636450000002793705137280075684_'
      gradientUnits='userSpaceOnUse'
      x1={55}
      y1={3}
      x2={105}
      y2={3}
      gradientTransform='matrix(1 0 0 -1 0 116)'
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#largeFile_app4_svg__SVGID_00000173125541893308636450000002793705137280075684_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M55 113h50'
    />
    <path className='largeFile_app4_svg__st3' d='M90.3 57h50M90.3 71h50' />
    <linearGradient
      id='largeFile_app4_svg__SVGID_00000070102721419803354750000007441853566973617042_'
      gradientUnits='userSpaceOnUse'
      x1={73.185}
      y1={0.855}
      x2={86.815}
      y2={14.485}
      gradientTransform='matrix(1 0 0 -1 0 116)'
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#252a3a' stopOpacity={0.8} />
    </linearGradient>
    <path
      fill='url(#largeFile_app4_svg__SVGID_00000070102721419803354750000007441853566973617042_)'
      d='M90 112H70l2.7-10h14.6z'
    />
    <g>
      <path
        className='largeFile_app4_svg__st0'
        d='M103.1 36.8h-8.4v8.7h-4.4V24.2h13.9v3.6h-9.5v5.5h8.4v3.5zM123 27.8h-6.5v17.8h-4.4V27.8h-6.4v-3.6H123v3.6zM129.9 38v7.5h-4.4V24.2h8.3c1.3 0 2.4.2 3.4.5 1 .4 1.8.8 2.5 1.5.7.6 1.2 1.4 1.6 2.2s.5 1.8.5 2.8c0 1-.2 2-.5 2.8s-.9 1.5-1.6 2.1c-.7.6-1.5 1-2.5 1.4-1 .3-2.1.5-3.4.5h-3.9zm0-3.5h3.9c.6 0 1.2-.1 1.6-.2.5-.2.8-.4 1.1-.7.3-.3.5-.6.6-1s.2-.8.2-1.3-.1-.9-.2-1.3-.3-.8-.6-1.1c-.3-.3-.7-.6-1.1-.8-.5-.2-1-.3-1.6-.3h-3.9v6.7z'
      />
    </g>
  </svg>
)

export default SvgLargeFileApp4
