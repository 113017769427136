import React from 'react'

const SvgLargeFileApp2 = props => (
  <svg id='largeFile_app2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>{'.largeFile_app2_svg__st11{fill:#ea5c26}'}</style>
    <linearGradient
      id='largeFile_app2_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={16.55}
      y1={114.45}
      x2={143.45}
      y2={-12.45}
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#242939' />
    </linearGradient>
    <path
      d='M153 101H7c-3.31 0-6-2.69-6-6V7c0-3.31 2.69-6 6-6h146c3.31 0 6 2.69 6 6v88c0 3.31-2.69 6-6 6z'
      fill='none'
      stroke='url(#largeFile_app2_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={54.465}
      y1={113}
      x2={104.465}
      y2={113}
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#242939' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#largeFile_app2_svg__SVGID_2_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M54.47 113h50'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={72.65}
      y1={115.143}
      x2={86.281}
      y2={101.512}
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path fill='url(#largeFile_app2_svg__SVGID_3_)' d='M89.42 112H69.51l2.65-10h14.61z' />
    <linearGradient
      id='largeFile_app2_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={149.372}
      y1={47.716}
      x2={103.624}
      y2={47.716}
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M103.62 48.25h41.85c.23.84.99 1.46 1.91 1.46 1.1 0 1.99-.89 1.99-1.99s-.89-1.99-1.99-1.99c-.91 0-1.68.62-1.91 1.46h-41.85'
      fill='url(#largeFile_app2_svg__SVGID_4_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={103.624}
      y1={33.732}
      x2={150.192}
      y2={33.732}
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M103.62 40.97h32.84l10.77-10.77c.76.43 1.74.33 2.38-.32.78-.78.78-2.04 0-2.81a1.98 1.98 0 00-2.81 0c-.65.65-.75 1.62-.32 2.38l-10.46 10.46h-32.4'
      fill='url(#largeFile_app2_svg__SVGID_5_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={103.624}
      y1={1728.868}
      x2={132.025}
      y2={1728.868}
      gradientTransform='matrix(1 0 0 -1 0 1790.987)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M103.62 54.88h14.67l10.77 10.77c.76-.43 1.74-.33 2.38.32.78.78.78 2.04 0 2.81-.78.78-2.04.78-2.81 0-.65-.65-.75-1.62-.32-2.38l-10.46-10.46h-14.23'
      fill='url(#largeFile_app2_svg__SVGID_6_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={151.328}
      y1={1711.732}
      x2={129.74}
      y2={1711.732}
      gradientTransform='matrix(1 0 0 -1 0 1774.987)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M129.74 56.02h7.86l10.77 10.77c.76-.43 1.74-.33 2.38.32.78.78.78 2.04 0 2.81-.78.78-2.04.78-2.81 0-.65-.65-.75-1.62-.32-2.38l-10.46-10.46h-7.42'
      fill='url(#largeFile_app2_svg__SVGID_7_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={1342.832}
      y1={47.716}
      x2={1388.58}
      y2={47.716}
      gradientTransform='matrix(-1 0 0 1 1400.16 0)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M57.33 48.25H15.48c-.23.84-.99 1.46-1.91 1.46-1.1 0-1.99-.89-1.99-1.99s.89-1.99 1.99-1.99c.91 0 1.68.62 1.91 1.46h41.85'
      fill='url(#largeFile_app2_svg__SVGID_8_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={1342.832}
      y1={33.732}
      x2={1389.399}
      y2={33.732}
      gradientTransform='matrix(-1 0 0 1 1400.16 0)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M57.33 40.97H24.49L13.72 30.21c-.76.43-1.74.33-2.38-.32a1.98 1.98 0 010-2.81 1.98 1.98 0 012.81 0c.65.65.75 1.62.32 2.38l10.46 10.46h32.4'
      fill='url(#largeFile_app2_svg__SVGID_9_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={1342.832}
      y1={1728.868}
      x2={1371.232}
      y2={1728.868}
      gradientTransform='rotate(180 700.08 895.493)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M57.33 54.88H42.66L31.89 65.65c-.76-.43-1.74-.33-2.38.32a1.98 1.98 0 000 2.81c.78.78 2.04.78 2.81 0 .65-.65.75-1.62.32-2.38L43.1 55.94h14.23'
      fill='url(#largeFile_app2_svg__SVGID_10_)'
    />
    <linearGradient
      id='largeFile_app2_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={1390.535}
      y1={1711.732}
      x2={1368.947}
      y2={1711.732}
      gradientTransform='rotate(180 700.08 887.493)'
    >
      <stop offset={0} stopColor='#242939' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#242939' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M31.21 56.02h-7.86L12.59 66.78c-.76-.43-1.74-.33-2.38.32a1.98 1.98 0 000 2.81c.78.78 2.04.78 2.81 0 .65-.65.75-1.62.32-2.38L23.8 57.07h7.42'
      fill='url(#largeFile_app2_svg__SVGID_11_)'
    />
    <path
      className='largeFile_app2_svg__st11'
      d='M72.24 39.85H60.51a21.75 21.75 0 00-3.02 10.27h14.07c.04-3.49.27-7 .68-10.27zM71.55 51.88H57.49c.15 3.74 1.23 7.24 3.03 10.27h11.67c-.4-3.28-.61-6.8-.64-10.27zM76.59 72.81c.94.12 1.9.19 2.88.19.86 0 1.7-.05 2.53-.15.86-2.09 1.66-5.11 2.23-8.94h-9.85c.56 3.8 1.36 6.81 2.21 8.9zM84.16 38.09c-.61-3.91-1.46-6.95-2.36-8.96-.77-.09-1.54-.13-2.33-.13-.91 0-1.8.06-2.68.17-.9 2.02-1.74 5.04-2.34 8.92h9.71zM74.14 62.15h10.33c.37-3.01.6-6.45.62-10.27H73.52c.02 3.82.25 7.26.62 10.27zM86.43 62.15h11.99c1.79-3.04 2.88-6.53 3.03-10.27H87.06c-.03 3.47-.24 6.99-.63 10.27zM74.51 29.57c-5.24 1.21-9.77 4.3-12.84 8.52h10.81c.49-3.25 1.17-6.18 2.03-8.52zM61.67 63.91c3.03 4.17 7.49 7.24 12.66 8.48-.82-2.36-1.46-5.28-1.92-8.48H61.67zM84.25 72.47c5.31-1.18 9.91-4.29 13.01-8.56H86.19c-.46 3.24-1.11 6.19-1.94 8.56zM97.26 38.09c-3.14-4.31-7.8-7.45-13.19-8.6.87 2.35 1.56 5.31 2.06 8.6h11.13zM74.2 39.85c-.39 3-.63 6.44-.67 10.27H85.1c-.04-3.83-.29-7.27-.67-10.27H74.2zM87.05 50.12h14.39c-.15-3.74-1.23-7.24-3.02-10.27H86.37c.41 3.27.64 6.78.68 10.27z'
    />
  </svg>
)

export default SvgLargeFileApp2
